import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EXTENDED_SPINNER } from 'src/app/common/data/utils/models/utils.model';
import { environment } from 'src/environments/environment';
import { identificationModel, userRegisterModel } from '../models/auth.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  /**
   * Variable que contiene la url del gateway
   */
  gatewayUrl: string;

  constructor(private http: HttpClient) {
    // Seteamos el la url base
    this.gatewayUrl = environment.INASS_CNE;
  }

  /**
   * @description Servicio para realizar login
   * @param identificationModel Datos del usuario necesarios para hacer login
   * @returns
   */
  createInassUserService(userData: userRegisterModel): Observable<any> {
    let data = {
      params: userData,
    };
    return this.http.post(this.gatewayUrl + 'create_inass_user', data, {
      context: new HttpContext().set(EXTENDED_SPINNER, false),
    });
  }

  checkIdentificationService(identificationData: identificationModel): Observable<any> {
    let data = {
      params: identificationData,
    };
    return this.http.post(this.gatewayUrl + 'check_identification_ext', data, {
      context: new HttpContext().set(EXTENDED_SPINNER, false),
    });
  }

  getVenezuelanStates(): Observable<any> {
    let params = {};
    return this.http.get(this.gatewayUrl + 'get_venezuelan_states', { params });
  }

  getMunicipalitiesByStateService(id: number): Observable<any> {
    let params = {
      state_id: id,
    };
    return this.http.get(this.gatewayUrl + 'get_municipalities_by_state', { params });
  }

  getParishesByMunicipalityService(id: number): Observable<any> {
    let params = {
      municipality_id: id,
    };
    return this.http.get(this.gatewayUrl + 'get_parishes_by_municipality', { params });
  }

  getLivingOptionsService(): Observable<any> {
    let params = {};
    return this.http.get(this.gatewayUrl + 'get_living_with_options', { params });
  }

  getIncomeConceptService(): Observable<any> {
    let params = {};
    return this.http.get(this.gatewayUrl + 'get_income_concept_options', { params });
  }

  getDiseaseService(): Observable<any> {
    let params = {};
    return this.http.get(this.gatewayUrl + 'get_disease_options', { params });
  }

  getHousingStatusService(): Observable<any> {
    let params = {};
    return this.http.get(this.gatewayUrl + 'get_housing_status_options', { params });
  }

  getHouseTypeService(): Observable<any> {
    let params = {};
    return this.http.get(this.gatewayUrl + 'get_house_type_options', { params });
  }

  getTreatmentPlaceService(): Observable<any> {
    let params = {};
    return this.http.get(this.gatewayUrl + 'get_treatment_place_options', { params });
  }

  getDisabilityService(): Observable<any> {
    let params = {};
    return this.http.get(this.gatewayUrl + 'get_disability_options', { params });
  }

  getFoodAccessService(): Observable<any> {
    let params = {};
    return this.http.get(this.gatewayUrl + 'get_food_access_options', { params });
  }

  getFeedingFrequencyService(): Observable<any> {
    let params = {};
    return this.http.get(this.gatewayUrl + 'get_feeding_frequency_options', { params });
  }

  getSocialOrganizationsService(): Observable<any> {
    let params = {};
    return this.http.get(this.gatewayUrl + 'get_social_organizations_options', { params });
  }

  getSocialMissionsService(): Observable<any> {
    let params = {};
    return this.http.get(this.gatewayUrl + 'get_social_missions_options', { params });
  }

  getActivityTypesService(): Observable<any> {
    let params = {};
    return this.http.get(this.gatewayUrl + 'get_activity_types_options', { params });
  }

  getSkillsService(): Observable<any> {
    let params = {};
    return this.http.get(this.gatewayUrl + 'get_skills_options', { params });
  }

  updateInassUserService(data: Record<string, any>): Observable<any> {
    return this.http.post(this.gatewayUrl + 'update_inass_user', data, {
      context: new HttpContext().set(EXTENDED_SPINNER, false),
    });
  }

  updateInassSurveryService(data: Record<string, any>, param: number): Observable<any> {
    return this.http.post(this.gatewayUrl + 'update_inass_survery/' + param, data, {
      context: new HttpContext().set(EXTENDED_SPINNER, false),
    });
  }

  SurveyPdfService(idhash: string): Observable<Blob> {
    return this.http.get(this.gatewayUrl + 'get_survey_pdf/' + idhash, { responseType: 'blob' });
  }

  getFamilyRelationshipService(): Observable<any> {
    let params = {};
    return this.http.get(this.gatewayUrl + 'get_family_relationship_options', { params });
  }

  getCollectionPlacesService(): Observable<any> {
    let params = {};
    return this.http.get(this.gatewayUrl + 'get_collection_places', { params });
  }

  getStaffReasonService(): Observable<any> {
    let params = {};
    return this.http.get(this.gatewayUrl + 'get_staff_reason_options', { params });
  }

  getCommunicationMediumsService(): Observable<any> {
    let params = {};
    return this.http.get(this.gatewayUrl + 'get_communication_mediums', { params });
  }

  getCommunicationFrequenciesService(): Observable<any> {
    let params = {};
    return this.http.get(this.gatewayUrl + 'get_communication_frequencies', { params });
  }

  getCivilStateOptionsService(): Observable<any> {
    let params = {};
    return this.http.get(this.gatewayUrl + 'get_civil_state_options', { params });
  }

  getEthnicityOptionsService(): Observable<any> {
    let params = {};
    return this.http.get(this.gatewayUrl + 'get_ethnicity_options', { params });
  }

  getEducationalLevelService(): Observable<any> {
    let params = {};
    return this.http.get(this.gatewayUrl + 'get_educational_level_options', { params });
  }
}
