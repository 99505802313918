import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm, FormArray, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { inass, qr_amm, check } from 'src/app/common/assets/images/images-routes';
import { CmmComponentFormModel } from 'src/app/common/data/forms/models/form.model';
import { AuthService } from '../../services/auth.service';
import { timeout } from 'rxjs/operators';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CmmAlertToastrModel } from 'src/app/common/data/dialogs/models/dialogs.model';
import { CmmDialogService } from 'src/app/common/services/dialogs.service';

interface LivingOption {
  id: number;
  name: string;
}

interface ControlGroup {
  [key: string]: any;
}

@Component({
  selector: 'cmp-auth-login-form',
  templateUrl: './auth-login-form.component.html',
  styleUrls: ['./auth-login-form.component.scss'],
})
export class AuthLoginFormComponent implements CmmComponentFormModel {
  $unsubscribe = new Subject<void>();

  isFieldDisabled: boolean = true;
  progress: number = 0;
  tooltipText: string = '0%';

  componentForm: FormGroup = new FormGroup([]);
  initialForm: FormGroup = new FormGroup([]);
  ingresosForm: FormGroup = new FormGroup([]);
  socialForm: FormGroup = new FormGroup([]);
  healthForm: FormGroup = new FormGroup([]);
  socialPartipitationForm: FormGroup = new FormGroup([]);
  familyForm: FormGroup = new FormGroup([]);
  inassForm: FormGroup = new FormGroup([]);

  currentDate: Date = new Date();

  documentTypeList: any[] = [];
  genderList: any[] = [];
  maritalStatusList: any[] = [];
  phoneOperatorList: any[] = [];
  stateList: any[] = [];
  municipalityList: any[] = [];
  parishList: any[] = [];
  selectedOption: string = '';
  ethnicityList: any[] = [];
  educationalLevelList: any[] = [];

  socioeconomic_data: boolean = false;
  basicHealthData: boolean = false;
  social_participation: boolean = false; // Cambio de nombre de variable
  family_data: boolean = false;
  worker_data: boolean = false;
  basic_info: boolean = false;
  initial_info: boolean = true;
  registerCompleted: boolean = false;

  livingWithOptions: string[] = [];
  livingWithAllOptions: any[] = [];
  selectedOptions: string[] = [];
  selectedLivingWithIds: number[] = [];
  errorMessage: string = '';

  incomeSources: string[] = [];
  incomeSourcesAll: any[] = [];
  selectedIncomeSources: string[] = [];
  selectedIncomeSourceIds: number[] = [];
  incomeErrorMessage: string = '';

  disabilities: string[] = [];
  allDisabilities: any[] = [];
  selectedDisabilities: string[] = [];
  disabilityErrorMessage: string | null = null;
  selectedDisabilitiesWithIds: number[] = [];

  diseases: string[] = [];
  allDiseases: any[] = [];
  selectedDiseases: string[] = [];
  diseaseErrorMessage: string | null = null;
  selecteddiseasesWithIds: number[] = [];

  treatmentCenters: string[] = [];
  allTreatmentCenters: any[] = [];
  selectedTreatmentCenters: string[] = [];
  treatmentCenterErrorMessage: string | null = null;
  selectedTreatmentCentersWithIds: number[] = [];

  selectedTreatment: string | null = null;
  treatmentErrorMessage: string | null = null;

  selectedFoodFrequency: string | null = null;
  foodFrequencyErrorMessage: string | null = null;

  selectedClapBenefit: string | null = null;
  clapBenefitErrorMessage: string | null = null;

  option_fb: string[] = [];
  response_fb: { [key: string]: string } = {};

  activities: string[] = [];
  allActivities: any[] = [];
  response_activities: { [key: string]: string } = {};

  organization: string[] = [];
  allOrganization: any[] = [];

  social_mission: string[] = [];
  socialMisionsAll: any[] = [];

  habilities: string[] = [];
  allHabilities: any[] = [];

  info_suministrate: string = '';

  inass = inass;
  qr_amm = qr_amm;
  check = check;

  token: string | undefined;

  latitude: any = 0.0;
  longitude: any = 0.0;

  create_inass_user: any = {};

  selectedLivingWithOptions: string[] = [];

  housingConditions: any[] = [];

  housingTypes: any[] = [];
  housingOtherType: boolean = false;
  otherParents: boolean = false;

  frequencyList: any[] = [];

  infoSources = [
    { label: 'Directamente por el o la adulta mayor', value: 'self' },
    { label: 'Por un familiar o amigo', value: 'family' },
    { label: 'Personal del INASS', value: 'staff' },
  ];

  infoStudent = [
    { label: 'Primaria', value: 'self' },
    { label: 'Secundaria', value: 'family' },
    { label: 'Universitaria', value: 'staff' },
    { label: 'Postgrado', value: 'post' },
  ];

  treatmentOptions = [
    { value: 'si', label: 'Sí' },
    { value: 'no', label: 'No' },
    { value: 'no_es_necesario', label: 'No es necesario' },
  ];

  clapBenefitOptions = [
    { value: true, label: 'Sí' },
    { value: false, label: 'No' },
  ];

  lawAtentionOptions = [
    { value: true, label: 'Sí' },
    { value: false, label: 'No' },
  ];

  foodFrequencyOptions: any[] = [];

  relations: any[] = [];

  campusList: any[] = [];

  captureReasons: any[] = [];

  inass_user_id: number = 0;
  inass_survey_id: number = 110;

  id_hash_pdf: string = '9f7d9fe9-b0c7-4e52-8c7c-fc73c24f2270';

  isValid: boolean = true;

  selectedOrganizationOptions: string[] = [];

  selectedSocialMissionOptions: string[] = [];

  selectedActivityOptions: string[] = [];

  selectedHabilitiesOptions: string[] = [];
  habilitiesErrorMessage: string = '';
  errorOrganizationMessage: string = '';
  errorMisionMessage: string = '';
  errorActivitiesMessage: string = '';
  otherHabilities: boolean = false;

  selectedOrganizationsIds: number[] = [];
  selectedActivityIds: number[] = [];
  selectedSocialIds: number[] = [];
  skillsIds: number[] = [];

  // pdfUrl: string | null = null;

  constructor(private formBuilder: FormBuilder, private authService: AuthService, private recaptchaV3Service: ReCaptchaV3Service, private cmmDialogsService: CmmDialogService) {
    this.token = undefined;
  }

  public send(form: NgForm): void {
    this.recaptchaV3Service.execute('6Le0bBMqAAAAAG2YEGl_OO2WuLszmNG4rOuWD4UX').subscribe((token) => {
      // Include the token in your form data or perform necessary actions
    });
  }

  checkIdentification(): void {
    const identificationData = {
      document_type: this.initialForm.value.typeDocument,
      identification_id: this.initialForm.value.documentNumber, // ID de identificación del usuario
    };

    this.authService
      .checkIdentificationService(identificationData)
      .pipe(
        timeout(5000) // 5000 ms = 5 segundos
      )
      .subscribe(
        (response) => {
          this.componentForm.patchValue({
            name: response.result.Nombre ? response.result.Nombre : this.componentForm.get('name')?.enable(), // Asignar el valor del nombre obtenido de la API al campo 'name'
            vat: identificationData.identification_id,
            user_nationality: identificationData.document_type,
            user_gender: response.result.sexo,
          });
          this.create_inass_user = {
            name: response.result.Nombre, // Asignar el valor del nombre obtenido de la API al campo 'name'
            vat: identificationData.identification_id,
            user_nationality: identificationData.document_type,
          };
          // Manejar la respuesta de la API según tus necesidades
        },
        (error) => {
          console.error('Error al llamar a la API:', error);
          this.componentForm.get('name')?.enable();
          this.create_inass_user = {
            vat: identificationData.identification_id,
            user_nationality: identificationData.document_type,
          };
        }
      );
  }

  updateLivingWithResponse(event: any, option: string): void {
    const isChecked = event.checked;

    if (option === 'Solo/Sola' && isChecked) {
      // Si se selecciona 'Solo/Sola', desmarcar todas las otras opciones
      this.selectedOptions = ['Solo/Sola'];
    } else if (option === 'Solo/Sola' && !isChecked) {
      // Si se desmarca 'Solo/Sola', simplemente quitarla de la lista de seleccionados
      this.selectedOptions = this.selectedOptions.filter((opt) => opt !== 'Solo/Sola');
    } else {
      // Si se selecciona una opción diferente de 'Solo/Sola'
      if (isChecked) {
        this.selectedOptions.push(option);
      } else {
        this.selectedOptions = this.selectedOptions.filter((opt) => opt !== option);
      }
      // Desmarcar 'Solo/Sola' si alguna otra opción está seleccionada
      this.selectedOptions = this.selectedOptions.filter((opt) => opt !== 'Solo/Sola');
    }
  }

  actualizarRespuesta2(opcion: string, valor: any) {
    this.response_fb[opcion] = this.socialPartipitationForm.value.frequencySelection;
  }

  actualizarRespuesta3(opcion: string, valor: string) {
    this.response_activities[opcion] = valor;
  }

  onSelectionChange() {
    const stateId = this.stateList.find((item) => item.name === this.componentForm.value.state_id);
    if (stateId != undefined) {
      this.authService.getMunicipalitiesByStateService(stateId.id).subscribe(
        (data: LivingOption[]) => {
          this.municipalityList = data;
          this.create_inass_user.state_id = stateId.id;
          this.componentForm.get('municipality_id')!.patchValue([]);
          this.componentForm.get('parish_id')!.patchValue([]);
        },
        (error: any) => {
          console.error('Error al llamar a la API:', error);
          // Manejar errores aquí
        }
      );
    }
  }

  onSelectionChange2() {
    const municipalityId = this.municipalityList.find((item) => item.name === this.componentForm.value.municipality_id);
    if (municipalityId != undefined) {
      this.authService.getParishesByMunicipalityService(municipalityId.id).subscribe(
        (data: LivingOption[]) => {
          this.componentForm.get('parish_id')!.reset([]);
          this.parishList = data;
          this.create_inass_user.municipality_id = municipalityId.id;
        },
        (error) => {
          console.error('Error al llamar a la API:', error);
          // Manejar errores aquí
        }
      );
    }
  }

  onSelectionChange3() {
    const parishId = this.parishList.find((item) => item.name === this.componentForm.value.parish_id);
    if (parishId != undefined) {
      this.create_inass_user.parish_id = parishId.id;
    }
  }

  shouldShowAdditionalSection(): boolean {
    return this.selectedOption === 'si';
  }

  ngOnInit(): void {
    this.createForm();

    this.getCurrentPosition();

    this.authService.getVenezuelanStates().subscribe(
      (data: LivingOption[]) => {
        this.stateList = data;
      },
      (error) => {
        console.error('Error al llamar a la API:', error);
        // Manejar errores aquí
      }
    );

    this.authService.getCivilStateOptionsService().subscribe(
      (data: LivingOption[]) => {
        this.maritalStatusList = data;
      },
      (error) => {
        console.error('Error al llamar a la API:', error);
        // Manejar errores aquí
      }
    );

    this.authService.getEthnicityOptionsService().subscribe(
      (data: LivingOption[]) => {
        this.ethnicityList = data;
      },
      (error) => {
        console.error('Error al llamar a la API:', error);
        // Manejar errores aquí
      }
    );

    this.authService.getEducationalLevelService().subscribe(
      (data: LivingOption[]) => {
        this.educationalLevelList = data;
      },
      (error) => {
        console.error('Error al llamar a la API:', error);
        // Manejar errores aquí
      }
    );
  }

  getCurrentPosition(): Promise<{ latitude: number; longitude: number }> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;
            resolve({ latitude: this.latitude, longitude: this.longitude });
          },
          (error) => reject(error)
        );
      } else {
        reject(new Error('Geolocation is not supported by this browser.'));
      }
    });
  }

  createForm() {
    this.documentTypeList = [
      {
        documentKey: 'V',
        documentTypeName: 'Venezolano',
      },
      {
        documentKey: 'E',
        documentTypeName: 'Extranjero',
      },
    ];
    this.genderList = [
      {
        genderKey: 'female',
        genderName: 'Femenino',
      },
      {
        genderKey: 'male',
        genderName: 'Masculino',
      },
    ];

    this.phoneOperatorList = [
      {
        documentKey: '416',
        documentTypeName: '0416',
      },
      {
        documentKey: '426',
        documentTypeName: '0426',
      },
      {
        documentKey: '414',
        documentTypeName: '0414',
      },
      {
        documentKey: '424',
        documentTypeName: '0424',
      },
      {
        documentKey: '412',
        documentTypeName: '0412',
      },
    ];

    this.initialForm = this.formBuilder.group({
      documentNumber: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(8)])],
      typeDocument: ['', Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(2)])],
    });

    this.componentForm = this.formBuilder.group({
      name: [{ value: '', disabled: true }, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
      vat: [{ value: '', disabled: true }, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
      user_nationality: [{ value: '', disabled: true }, Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(2)])],
      birthdate: ['', Validators.compose([Validators.required])],
      phone: ['', Validators.compose([Validators.required, Validators.minLength(7), Validators.maxLength(7)])],
      phoneOperator: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(4)])],
      phone2: ['', Validators.compose([Validators.minLength(11), Validators.maxLength(11)])],
      state_id: ['', Validators.compose([Validators.required])],
      municipality_id: ['', Validators.compose([Validators.required])],
      parish_id: ['', Validators.compose([Validators.required])],
      user_gender: ['', Validators.compose([Validators.required])],
      marital_status: ['', Validators.compose([Validators.required])],
      street: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(40)])],
      street2: ['', Validators.compose([Validators.minLength(3), Validators.maxLength(40)])],
      house_number: ['', Validators.compose([Validators.minLength(3), Validators.maxLength(40)])],
      reference_point: ['', Validators.compose([Validators.minLength(3), Validators.maxLength(40)])],
      ethnicity: ['', Validators.compose([Validators.required])],
      educational_level: ['', Validators.compose([Validators.required])],
      is_inass_user: true,
    });

    this.socialForm = this.formBuilder.group({
      permanentIncome: ['', Validators.required],
      housingCondition: ['', Validators.required],
      housingType: ['', Validators.required],
      housingOtherType: ['', Validators.compose([Validators.minLength(3), Validators.maxLength(40)])],
    });

    this.healthForm = this.formBuilder.group({
      clapBenefit: ['', Validators.required],
      foodFrequency: ['', Validators.required],
    });

    this.socialPartipitationForm = this.formBuilder.group({
      info_suministrate: ['', Validators.required],
      frequencySelection: [''],
      lawAtention: ['', Validators.required],
      otherHabilities: ['', Validators.compose([Validators.minLength(3), Validators.maxLength(40)])],
    });

    this.familyForm = this.formBuilder.group({
      parentType: ['', Validators.required],
      name: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
      vat: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
      user_nationality: ['', Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(2)])],
      phone: ['', Validators.compose([Validators.required, Validators.minLength(11), Validators.maxLength(11)])],
      otherParents: ['', Validators.compose([Validators.minLength(3), Validators.maxLength(40)])],
    });

    this.inassForm = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
      vat: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
      user_nationality: ['', Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(2)])],
      phone: ['', Validators.compose([Validators.required, Validators.minLength(11), Validators.maxLength(11)])],
      campusSelection: ['', Validators.required],
      captureReason: ['', Validators.required],
    });
  }

  goBackInitialInfo(): void {
    this.initial_info = true;
    this.basic_info = false;
    this.socioeconomic_data = false;
    this.basicHealthData = false;
    this.social_participation = false;
  }

  goBackBasicInfo(): void {
    this.initial_info = false;
    this.basic_info = true;
    this.socioeconomic_data = false;
    this.basicHealthData = false;
    this.social_participation = false;
  }

  goBackSocialInfo(): void {
    this.initial_info = false;
    this.basic_info = false;
    this.socioeconomic_data = true;
    this.basicHealthData = false;
    this.social_participation = false;
  }

  goBackHealthInfo(): void {
    this.initial_info = false;
    this.basic_info = false;
    this.socioeconomic_data = false;
    this.basicHealthData = true;
    this.social_participation = false;
  }

  goBack(): void {}

  async toggleView(): Promise<void> {
    if (this.initialForm.valid) {
      await this.recaptchaV3Service.execute('6Le0bBMqAAAAAG2YEGl_OO2WuLszmNG4rOuWD4UX').subscribe((token) => {
        // Include the token in your form data or perform necessary actions
        this.initialForm.get('recaptcha')?.setValue(this.token);
      });
      // if (this.token) {
      await this.checkIdentification();

      this.initial_info = false;
      this.basic_info = true;
      this.socioeconomic_data = false;
      this.basicHealthData = false;
      this.social_participation = false;

      // } else {
      //   console.error('reCAPTCHA validation failed');
      //   // Optionally, mark the recaptcha control as touched to show the validation error
      //   this.initialForm.get('recaptcha')?.markAsTouched();
      // }
    } else {
      Object.keys(this.initialForm.controls).forEach((field) => {
        const control = this.initialForm.get(field);
        if (control) {
          control.markAsTouched({ onlySelf: true });
        }
      });
    }
  }

  onRecaptchaResolved(token: string | null): void {
    this.token = token ?? ''; // Use an empty string if the token is null
    this.initialForm.get('recaptcha')?.setValue(this.token);
  }

  cleanPhoneNumber(phone: string): string {
    // Eliminar el signo '+' si está presente
    let cleanedPhone = phone.replace(/^\+/, '');

    // Si el número comienza con '0', eliminarlo
    if (cleanedPhone.startsWith('0')) {
      cleanedPhone = cleanedPhone.substring(1);
    }

    return cleanedPhone;
  }

  basicInfoView(): void {
    this.create_inass_user.name = this.create_inass_user.name ? this.create_inass_user.name : this.componentForm.value.name;
    this.create_inass_user.phone = '+58' + this.componentForm.value.phoneOperator + this.componentForm.value.phone;
    this.create_inass_user.mobile = this.componentForm.value.phone2! ? '+58' + this.cleanPhoneNumber(this.componentForm.value.phone2) : '';
    this.create_inass_user.user_gender = this.componentForm.value.user_gender;
    this.create_inass_user.street = this.componentForm.value.street;
    this.create_inass_user.street2 = this.componentForm.value.street2;
    this.create_inass_user.house_number = this.componentForm.value.house_number;
    this.create_inass_user.reference_point = this.componentForm.value.reference_point;
    this.create_inass_user.civil_state = this.componentForm.value.marital_status;
    this.create_inass_user.ethnicity_ids = [this.componentForm.value.ethnicity];
    this.create_inass_user.educational_level = this.componentForm.value.educational_level;
    this.create_inass_user.is_inass_user = true;
    this.create_inass_user.latitude = this.latitude;
    this.create_inass_user.longitude = this.longitude;
    if (this.componentForm.valid) {
      const birthdateControl = this.componentForm.get('birthdate');
      if (birthdateControl && birthdateControl.value) {
        const dateParts = birthdateControl.value.split('/');
        if (dateParts.length === 3) {
          const day = dateParts[1].padStart(2, '0'); // Añade un 0 delante si el día tiene solo un dígito
          const month = dateParts[0].padStart(2, '0'); // Añade un 0 delante si el mes tiene solo un dígito
          const year = dateParts[2];

          // Formatea la fecha como 'YYYY/MM/DD'
          const formattedDate = `${year}-${month}-${day}`;

          this.create_inass_user.birthdate = formattedDate;
          birthdateControl.setValue(formattedDate);
        }
      }

      this.authService.createInassUserService(this.create_inass_user).subscribe(
        (data) => {
          if (data.result.error) {
            // Armamos la data de la alerta
            const messagesData: CmmAlertToastrModel = {
              typeIcon: 'error',
              text: data.result.error,
            };

            // Abrimos la alerta con el mensaje
            this.cmmDialogsService.CmmAlertToastr(messagesData);
          } else {
            this.inass_user_id = data.result.res_partner_id;
            this.inass_survey_id = data.result.inass_survey_id;
            this.initial_info = false;
            this.basic_info = false;
            this.socioeconomic_data = true;
            this.basicHealthData = false;
            this.social_participation = false; // Cambio de variable
            this.progress = 20;
            this.tooltipText = '20%';
            this.authService.getLivingOptionsService().subscribe(
              (data: LivingOption[]) => {
                this.livingWithOptions = data.map((option) => option.name);
                this.livingWithAllOptions = data;
              },
              (error) => {
                console.error('Error al llamar a la API:', error);
                // Manejar errores aquí
              }
            );
            this.authService.getIncomeConceptService().subscribe(
              (data: LivingOption[]) => {
                this.incomeSources = data.map((option) => option.name);
                this.incomeSourcesAll = data;
              },
              (error) => {
                console.error('Error al llamar a la API:', error);
                // Manejar errores aquí
              }
            );

            this.authService.getHousingStatusService().subscribe(
              (data: LivingOption[]) => {
                this.housingConditions = data;
              },
              (error) => {
                console.error('Error al llamar a la API:', error);
              }
            );

            this.authService.getHouseTypeService().subscribe(
              (data: LivingOption[]) => {
                this.housingTypes = data;
              },
              (error) => {
                console.error('Error al llamar a la API:', error);
              }
            );
          }
        },
        (error) => {
          console.error('Error al llamar a la API:', error);
          // Manejar errores aquí
        }
      );
    } else {
      // Opcional: mostrar mensajes de error para los campos del formulario
      Object.keys(this.componentForm.controls).forEach((field) => {
        const control = this.componentForm.get(field);
        if (control) {
          control.markAsTouched({ onlySelf: true });
        }
      });
    }
  }

  selectionHousingType() {
    if (this.socialForm.value.housingType == 'otro') {
      this.housingOtherType = true;
    } else {
      this.housingOtherType = false;
    }
  }

  selectionParentType() {
    if (this.familyForm.value.housingType == 'otro') {
      this.otherParents = true;
    } else {
      this.otherParents = false;
    }
  }

  updateLivingWithOption(option: string, isChecked: boolean) {
    if (option === 'Solo/Sola') {
      // If "Solo/Sola" is selected, clear all other selections
      if (isChecked) {
        this.selectedLivingWithOptions = ['Solo/Sola'];
      } else {
        // If "Solo/Sola" is deselected, remove it from the array
        this.selectedLivingWithOptions = this.selectedLivingWithOptions.filter((opt) => opt !== 'Solo/Sola');
      }
    } else {
      // If any other option is selected or deselected
      if (isChecked) {
        // If "Solo/Sola" is selected, deselect it
        this.selectedLivingWithOptions = this.selectedLivingWithOptions.filter((opt) => opt !== 'Solo/Sola');
        // Add the new option
        this.selectedLivingWithOptions.push(option);
      } else {
        // Remove the option if it is deselected
        this.selectedLivingWithOptions = this.selectedLivingWithOptions.filter((opt) => opt !== option);
      }
    }
  }

  isLivingWithSelected(option: string): boolean {
    return this.selectedLivingWithOptions.includes(option);
  }

  updateIncomeSource(option: string, isChecked: boolean) {
    if (isChecked) {
      this.selectedIncomeSources.push(option);
    } else {
      const index = this.selectedIncomeSources.indexOf(option);
      if (index > -1) {
        this.selectedIncomeSources.splice(index, 1);
      }
    }
  }

  isIncomeSourceSelected(option: string): boolean {
    return this.selectedIncomeSources.includes(option);
  }

  updateDisabilityOption(option: string, isChecked: boolean): void {
    if (option === 'Ninguna') {
      if (isChecked) {
        this.selectedDisabilities = ['Ninguna'];
      } else {
        this.selectedDisabilities = this.selectedDisabilities.filter((opt) => opt !== 'Ninguna');
      }
    } else {
      if (isChecked) {
        this.selectedDisabilities = this.selectedDisabilities.filter((opt) => opt !== 'Ninguna');
        this.selectedDisabilities.push(option);
      } else {
        this.selectedDisabilities = this.selectedDisabilities.filter((opt) => opt !== option);
      }
    }
  }

  isDisabilitySelected(option: string): boolean {
    return this.selectedDisabilities.includes(option);
  }

  updateDiseaseOption(option: string, isChecked: boolean): void {
    if (option === 'Ninguna') {
      if (isChecked) {
        this.selectedDiseases = ['Ninguna'];
      } else {
        this.selectedDiseases = this.selectedDiseases.filter((opt) => opt !== 'Ninguna');
      }
    } else {
      if (isChecked) {
        this.selectedDiseases = this.selectedDiseases.filter((opt) => opt !== 'Ninguna');
        this.selectedDiseases.push(option);
      } else {
        this.selectedDiseases = this.selectedDiseases.filter((opt) => opt !== option);
      }
    }
  }

  isDiseaseSelected(option: string): boolean {
    return this.selectedDiseases.includes(option);
  }

  updateTreatmentCenterOption(option: string, isChecked: boolean): void {
    if (option === 'Ninguno') {
      if (isChecked) {
        this.selectedTreatmentCenters = ['Ninguno'];
      } else {
        this.selectedTreatmentCenters = this.selectedTreatmentCenters.filter((opt) => opt !== 'Ninguno');
      }
    } else {
      if (isChecked) {
        this.selectedTreatmentCenters = this.selectedTreatmentCenters.filter((opt) => opt !== 'Ninguno');
        this.selectedTreatmentCenters.push(option);
      } else {
        this.selectedTreatmentCenters = this.selectedTreatmentCenters.filter((opt) => opt !== option);
      }
    }
  }

  isTreatmentCenterSelected(option: string): boolean {
    return this.selectedTreatmentCenters.includes(option);
  }

  async toggleSocioeconomicData(): Promise<void> {
    if (this.selectedLivingWithOptions.length === 0) {
      this.errorMessage = 'Debe seleccionar al menos una opción.';
      return;
    }

    this.selectedLivingWithIds = this.selectedLivingWithOptions
      .map((option) => {
        const match = this.livingWithAllOptions.find((livingWithOption) => livingWithOption.name === option);
        return match ? match.id : null;
      })
      .filter((id) => id !== null) as number[];

    if (this.selectedIncomeSources.length === 0) {
      this.incomeErrorMessage = 'Debe seleccionar al menos una fuente de ingreso.';
      return;
    }

    this.selectedIncomeSourceIds = this.selectedIncomeSources
      .map((option) => {
        const match = this.incomeSourcesAll.find((incomeSourceOption) => incomeSourceOption.name === option);
        return match ? match.id : null;
      })
      .filter((id) => id !== null) as number[];

    let data = {
      params: {
        id: this.inass_user_id,
        living_with_ids: this.selectedLivingWithIds,
        income_source_ids: this.selectedIncomeSourceIds,
        housing_status: this.socialForm.value.housingCondition,
        house_type: this.socialForm.value.housingType,
        other_house_type: this.socialForm.value.housingOtherType,
      },
    };

    this.authService.updateInassUserService(data).subscribe(
      async (data) => {
        // Marca esta función como async para poder usar await dentro de ella
        if (data.result.error) {
          // Armamos la data de la alerta
          const messagesData: CmmAlertToastrModel = {
            typeIcon: 'error',
            text: data.result.error,
          };

          // Abrimos la alerta con el mensaje
          this.cmmDialogsService.CmmAlertToastr(messagesData);
        } else {
          let data2 = {
            params: {
              survey_input_lines: [
                JSON.stringify(this.selectedLivingWithOptions) + ' + 1. ¿Con quién vive usted?',
                JSON.stringify(this.selectedIncomeSources) + ' + 2. Seleccione por cuál de estos conceptos recibe usted ingresos económicos:',
                this.socialForm.value.permanentIncome + ' + 3. ¿Recibe ingresos económicos de manera permanente?',
                this.socialForm.value.housingCondition + ' + 4. Condición de vivienda: + ',
                this.socialForm.value.housingType + ' + 5. Tipo de vivienda:',
              ],
            },
          };
          const register = await this.registerResponses(data2); // Usa await aquí
          if (register) {
            this.basic_info = false;
            this.socioeconomic_data = false;
            this.basicHealthData = true;
            this.social_participation = false;
            this.progress = 40;
            this.tooltipText = '40%';

            // Llamadas a los servicios API
            this.authService.getDisabilityService().subscribe(
              (data: LivingOption[]) => {
                this.disabilities = data.map((option) => option.name);
                this.allDisabilities = data;
              },
              (error) => {
                console.error('Error al llamar a la API:', error);
              }
            );

            this.authService.getDiseaseService().subscribe(
              (data: LivingOption[]) => {
                this.diseases = data.map((option) => option.name);
                this.allDiseases = data;
              },
              (error) => {
                console.error('Error al llamar a la API:', error);
              }
            );

            this.authService.getTreatmentPlaceService().subscribe(
              (data: LivingOption[]) => {
                this.treatmentCenters = data.map((option) => option.name);
                this.allTreatmentCenters = data;
              },
              (error) => {
                console.error('Error al llamar a la API:', error);
              }
            );

            this.authService.getFeedingFrequencyService().subscribe(
              (data: LivingOption[]) => {
                this.foodFrequencyOptions = data;
              },
              (error) => {
                console.error('Error al llamar a la API:', error);
              }
            );
          }
        }
      },
      (error) => {
        console.error('Error al llamar a la API:', error);
        // Manejar errores aquí
      }
    );
  }

  async toggleHealthData(): Promise<void> {
    if (this.healthForm.valid) {
      if (this.selectedDisabilities.length === 0) {
        this.disabilityErrorMessage = 'Debe seleccionar al menos una opción.';
        return;
      }

      this.selectedDisabilitiesWithIds = this.selectedDisabilities
        .map((option) => {
          const match = this.allDisabilities.find((disabilitiesWithOption) => disabilitiesWithOption.name === option);
          return match ? match.id : null;
        })
        .filter((id) => id !== null) as number[];

      if (this.selectedDiseases.length === 0) {
        this.diseaseErrorMessage = 'Debe seleccionar al menos una opción.';
        return;
      }

      this.selecteddiseasesWithIds = this.selectedDiseases
        .map((option) => {
          const match = this.allDiseases.find((diseasesWithOption) => diseasesWithOption.name === option);
          return match ? match.id : null;
        })
        .filter((id) => id !== null) as number[];

      if (this.selectedTreatmentCenters.length === 0) {
        this.treatmentErrorMessage = 'Debe seleccionar al menos una opción.';
        return;
      }

      this.selectedTreatmentCentersWithIds = this.selectedTreatmentCenters
        .map((option) => {
          const match = this.allTreatmentCenters.find((treatmentWithOption) => treatmentWithOption.name === option);
          return match ? match.id : null;
        })
        .filter((id) => id !== null) as number[];

      let data = {
        params: {
          id: this.inass_user_id,
          disease_ids: this.selecteddiseasesWithIds,
          disability_ids: this.selectedDisabilitiesWithIds,
          treatment_place_ids: this.selectedTreatmentCentersWithIds,
          feeding_frequency: this.healthForm.value.foodFrequency,
          clap_benefit: this.healthForm.value.clapBenefit,
        },
      };
      this.authService.updateInassUserService(data).subscribe(
        async (data) => {
          if (data.result.error) {
            // Armamos la data de la alerta
            const messagesData: CmmAlertToastrModel = {
              typeIcon: 'error',
              text: data.result.error,
            };

            // Abrimos la alerta con el mensaje
            this.cmmDialogsService.CmmAlertToastr(messagesData);
          } else {
            let data2 = {
              params: {
                survey_input_lines: [
                  `${JSON.stringify(this.selectedDisabilities)} + 6. ¿Tiene alguna discapacidad?`,
                  `${JSON.stringify(this.selectedDiseases)} + 7. ¿Padece una o varias de estas enfermedades?`,
                  `${JSON.stringify(this.selectedTreatmentCenters)} + 8. Indique dónde recibe tratamiento médico:`,
                  `${this.healthForm.value.clapBenefit} + 9. ¿Recibe usted el beneficio de alimentos CLAP?`,
                  `${this.healthForm.value.foodFrequency} + 10. ¿Con qué frecuencia se alimenta usted?`,
                ],
              },
            };
            const register = await this.registerResponses(data2); // Usa await aquí
            if (register) {
              this.basic_info = false;
              this.socioeconomic_data = false;
              this.basicHealthData = false;
              this.social_participation = true; // Cambio de variable
              this.progress = 60;
              this.tooltipText = '60%';
              this.authService.getCommunicationMediumsService().subscribe(
                (data: LivingOption[]) => {
                  this.option_fb = data.map((option) => option.name);
                },
                (error) => {
                  console.error('Error al llamar a la API:', error);
                  // Manejar errores aquí
                }
              );

              this.authService.getCommunicationFrequenciesService().subscribe(
                (data: LivingOption[]) => {
                  this.frequencyList = data;
                },
                (error) => {
                  console.error('Error al llamar a la API:', error);
                  // Manejar errores aquí
                }
              );

              this.authService.getSocialOrganizationsService().subscribe(
                (data: LivingOption[]) => {
                  this.organization = data.map((option) => option.name);
                  this.allOrganization = data;
                },
                (error) => {
                  console.error('Error al llamar a la API:', error);
                  // Manejar errores aquí
                }
              );

              this.authService.getSocialMissionsService().subscribe(
                (data: LivingOption[]) => {
                  this.social_mission = data.map((option) => option.name);
                  this.socialMisionsAll = data;
                },
                (error) => {
                  console.error('Error al llamar a la API:', error);
                  // Manejar errores aquí
                }
              );

              this.authService.getActivityTypesService().subscribe(
                (data: LivingOption[]) => {
                  this.activities = data.map((option) => option.name);
                  this.allActivities = data;
                },
                (error) => {
                  console.error('Error al llamar a la API:', error);
                  // Manejar errores aquí
                }
              );

              this.authService.getSkillsService().subscribe(
                (data: LivingOption[]) => {
                  this.habilities = data.map((option) => option.name);
                  this.allHabilities = data;
                },
                (error) => {
                  console.error('Error al llamar a la API:', error);
                  // Manejar errores aquí
                }
              );
            }
          }
        },
        (error) => {
          console.error('Error al llamar a la API:', error);
          // Manejar errores aquí
        }
      );
    } else {
      Object.keys(this.healthForm.controls).forEach((field) => {
        const control = this.healthForm.get(field);
        if (control) {
          control.markAsTouched({ onlySelf: true });
        }
      });
    }
  }

  updateOrganizationOption(option: string, isChecked: boolean) {
    if (option === 'Ninguna') {
      // Si "Ninguna" es seleccionada, limpia todas las otras selecciones
      if (isChecked) {
        this.selectedOrganizationOptions = ['Ninguna'];
      } else {
        // Si "Ninguna" es deseleccionada, remuévela del array
        this.selectedOrganizationOptions = this.selectedOrganizationOptions.filter((opt) => opt !== 'Ninguna');
      }
    } else {
      // Si cualquier otra opción es seleccionada o deseleccionada
      if (isChecked) {
        // Si "Ninguna" está seleccionada, deseleccionarla
        this.selectedOrganizationOptions = this.selectedOrganizationOptions.filter((opt) => opt !== 'Ninguna');
        // Añadir la nueva opción
        this.selectedOrganizationOptions.push(option);
      } else {
        // Remover la opción si es deseleccionada
        this.selectedOrganizationOptions = this.selectedOrganizationOptions.filter((opt) => opt !== option);
      }
    }
  }

  isOrganizationSelected(option: string): boolean {
    return this.selectedOrganizationOptions.includes(option);
  }

  updateSocialMissionOption(option: string, isChecked: boolean) {
    if (option === 'Ninguna') {
      if (isChecked) {
        // Si "Ninguna" es seleccionada, deselecciona todas las demás opciones
        this.selectedSocialMissionOptions = ['Ninguna'];
      } else {
        // Si "Ninguna" es deseleccionada, remuévela del array
        this.selectedSocialMissionOptions = this.selectedSocialMissionOptions.filter((opt) => opt !== 'Ninguna');
      }
    } else {
      if (isChecked) {
        // Si "Ninguna" está seleccionada, deseleccionarla
        this.selectedSocialMissionOptions = this.selectedSocialMissionOptions.filter((opt) => opt !== 'Ninguna');
        // Añadir la nueva opción
        this.selectedSocialMissionOptions.push(option);
      } else {
        // Remover la opción si es deseleccionada
        this.selectedSocialMissionOptions = this.selectedSocialMissionOptions.filter((opt) => opt !== option);
      }
    }
  }

  isSocialMissionSelected(option: string): boolean {
    return this.selectedSocialMissionOptions.includes(option);
  }

  updateActivityOption(option: string, isChecked: boolean) {
    if (option === 'Ninguna') {
      if (isChecked) {
        // Si "Ninguna" es seleccionada, deselecciona todas las demás opciones
        this.selectedActivityOptions = ['Ninguna'];
      } else {
        // Si "Ninguna" es deseleccionada, remuévela del array
        this.selectedActivityOptions = this.selectedActivityOptions.filter((opt) => opt !== 'Ninguna');
      }
    } else {
      if (isChecked) {
        // Si "Ninguna" está seleccionada, deseleccionarla
        this.selectedActivityOptions = this.selectedActivityOptions.filter((opt) => opt !== 'Ninguna');
        // Añadir la nueva opción
        this.selectedActivityOptions.push(option);
      } else {
        // Remover la opción si es deseleccionada
        this.selectedActivityOptions = this.selectedActivityOptions.filter((opt) => opt !== option);
      }
    }
  }

  isActivitySelected(option: string): boolean {
    return this.selectedActivityOptions.includes(option);
  }

  updateHabilitiesOption(option: string, isChecked: boolean) {
    if (isChecked) {
      // Añadir la nueva opción
      this.selectedHabilitiesOptions.push(option);
      if (option == 'Otro' && isChecked == true) {
        this.otherHabilities = true;
      } else {
        this.otherHabilities = false;
      }
    } else {
      // Remover la opción si es deseleccionada
      this.selectedHabilitiesOptions = this.selectedHabilitiesOptions.filter((opt) => opt !== option);
    }

    // Verificar si no hay opciones seleccionadas y mostrar el mensaje de error
    if (this.selectedHabilitiesOptions.length === 0) {
      this.habilitiesErrorMessage = 'Debe seleccionar al menos una habilidad o destreza.';
    } else {
      this.habilitiesErrorMessage = '';
    }
  }

  isHabilitySelected(option: string): boolean {
    return this.selectedHabilitiesOptions.includes(option);
  }

  async toggleSocialData(): Promise<void> {
    if (this.socialPartipitationForm.valid) {
      this.isValid = this.option_fb.every((opcion) => this.response_fb[opcion] !== undefined);

      if (this.isValid == false) {
        this.isValid = false;
        return;
      }

      if (this.selectedOrganizationOptions.length === 0) {
        this.errorOrganizationMessage = 'Debe seleccionar al menos una opción.';
        return;
      }

      this.selectedOrganizationsIds = this.selectedOrganizationOptions
        .map((option) => {
          const match = this.allOrganization.find((data) => data.name === option);
          return match ? match.id : null;
        })
        .filter((id) => id !== null) as number[];

      if (this.selectedSocialMissionOptions.length === 0) {
        this.errorMisionMessage = 'Debe seleccionar al menos una opción.';
        return;
      }

      this.selectedSocialIds = this.selectedSocialMissionOptions
        .map((option) => {
          const match = this.socialMisionsAll.find((data) => data.name === option);
          return match ? match.id : null;
        })
        .filter((id) => id !== null) as number[];

      if (this.selectedActivityOptions.length === 0) {
        this.errorActivitiesMessage = 'Debe seleccionar al menos una opción.';
        return;
      }

      this.selectedActivityIds = this.selectedActivityOptions
        .map((option) => {
          const match = this.allActivities.find((data) => data.name === option);
          return match ? match.id : null;
        })
        .filter((id) => id !== null) as number[];

      if (this.selectedHabilitiesOptions.length === 0) {
        this.habilitiesErrorMessage = 'Debe seleccionar al menos una habilidad o destreza.';
        return;
      }

      this.skillsIds = this.selectedHabilitiesOptions
        .map((option) => {
          const match = this.allHabilities.find((data) => data.name === option);
          return match ? match.id : null;
        })
        .filter((id) => id !== null) as number[];

      let data = {
        params: {
          id: this.inass_user_id,
          communication_usages: this.response_fb,
          social_organization_ids: this.selectedOrganizationsIds,
          social_mission_ids: this.selectedSocialIds,
          activity_type_ids: this.selectedActivityIds,
          skill_ids: this.skillsIds,
          knows_elderly_care_law: this.socialPartipitationForm.value.lawAtention,
          other_skill: this.socialPartipitationForm.value.otherHabilities,
        },
      };

      this.authService.updateInassUserService(data).subscribe(
        (data) => {
          if (data.result.error) {
            // Armamos la data de la alerta
            const messagesData: CmmAlertToastrModel = {
              typeIcon: 'error',
              text: data.result.error,
            };

            // Abrimos la alerta con el mensaje
            this.cmmDialogsService.CmmAlertToastr(messagesData);
          } else {
            this.progress = 80;
            this.tooltipText = '80%';
            if (this.socialPartipitationForm.value.info_suministrate == 'self') {
              let data = {
                params: {
                  completed_by: this.socialPartipitationForm.value.info_suministrate,
                },
              };
              this.authService.updateInassSurveryService(data, this.inass_survey_id).subscribe(
                async (data) => {
                  if (data.result.error) {
                    // Armamos la data de la alerta
                    const messagesData: CmmAlertToastrModel = {
                      typeIcon: 'error',
                      text: data.result.error,
                    };

                    // Abrimos la alerta con el mensaje
                    this.cmmDialogsService.CmmAlertToastr(messagesData);
                  } else {
                    let data2 = {
                      params: {
                        survey_input_lines: [
                          `${JSON.stringify(this.response_fb)} + 11. ¿Con qué frecuencia utiliza usted los siguientes medios de comunicación?`,
                          `${JSON.stringify(this.selectedOrganizationOptions)} + 12. ¿Participa usted en alguna Organización Social?`,
                          `${JSON.stringify(this.selectedSocialMissionOptions)} + 13. ¿Ha sido beneficiado una o más veces por alguna Misión Social?`,
                          `${JSON.stringify(this.selectedActivityOptions)} + 14. ¿Le gustaría participar en las siguientes actividades?`,
                          `${JSON.stringify(this.selectedHabilitiesOptions)} + 15. Indique sus principales habilidades y destrezas:`,
                          `${this.socialPartipitationForm.value.lawAtention} + 16. ¿Conoce la Ley Orgánica para la Atención y Desarrollo Integral de las Personas Adultas Mayores?`,
                          `${this.socialPartipitationForm.value.info_suministrate} + 17. La información fue suministrada por:`,
                        ],
                      },
                    };
                    const register = await this.registerResponses(data2); // Usa await aquí
                    if (register) {
                      this.social_participation = false;
                      this.id_hash_pdf = data.result.idhash;
                      this.registerCompleted = true;
                      this.progress = 100;
                      this.tooltipText = '100%';
                    }
                  }
                },
                (error) => {
                  console.error('Error al llamar a la API:', error);
                  // Manejar errores aquí
                }
              );
            }

            if (this.socialPartipitationForm.value.info_suministrate == 'family') {
              this.social_participation = false;
              this.family_data = true; // Cambio de variable
              this.authService.getFamilyRelationshipService().subscribe(
                (data: LivingOption[]) => {
                  this.relations = data;
                },
                (error) => {
                  console.error('Error al llamar a la API:', error);
                }
              );
            }

            if (this.socialPartipitationForm.value.info_suministrate == 'staff') {
              this.social_participation = false;
              this.worker_data = true; // Cambio de variable
              this.authService.getCollectionPlacesService().subscribe(
                (data: LivingOption[]) => {
                  this.campusList = data;
                },
                (error) => {
                  console.error('Error al llamar a la API:', error);
                }
              );
              this.authService.getStaffReasonService().subscribe(
                (data: LivingOption[]) => {
                  this.captureReasons = data;
                },
                (error) => {
                  console.error('Error al llamar a la API:', error);
                }
              );
            }
          }
        },
        (error) => {
          console.error('Error al llamar a la API:', error);
          // Manejar errores aquí
        }
      );
    } else {
      Object.keys(this.socialPartipitationForm.controls).forEach((field) => {
        const control = this.socialPartipitationForm.get(field);
        if (control) {
          control.markAsTouched({ onlySelf: true });
        }
      });
    }
  }

  toggleFamilyData(): void {
    if (this.familyForm.valid) {
      let data = {
        params: {
          completed_by: 'family',
          family_relationship: this.familyForm.value.parentType,
          family_member: {
            name: this.familyForm.value.name,
            vat: this.familyForm.value.vat,
            phone: '+58' + this.cleanPhoneNumber(this.familyForm.value.phone),
            user_nationality: this.familyForm.value.user_nationality,
            other_family_relationship: this.familyForm.value.otherParents,
          },
        },
      };
      this.authService.updateInassSurveryService(data, this.inass_survey_id).subscribe(
        (data) => {
          if (data.result.error) {
            // Armamos la data de la alerta
            const messagesData: CmmAlertToastrModel = {
              typeIcon: 'error',
              text: data.result.error,
            };

            // Abrimos la alerta con el mensaje
            this.cmmDialogsService.CmmAlertToastr(messagesData);
          } else {
            this.authService.updateInassSurveryService(data, this.inass_survey_id).subscribe(
              (data) => {
                this.family_data = false;
                this.id_hash_pdf = data.result.idhash;
                this.registerCompleted = true;
                this.progress = 100;
                this.tooltipText = '100%';
              },
              (error) => {
                console.error('Error al llamar a la API:', error);
                // Manejar errores aquí
              }
            );
          }
        },
        (error) => {
          console.error('Error al llamar a la API:', error);
          // Manejar errores aquí
        }
      );
    } else {
      Object.keys(this.familyForm.controls).forEach((field) => {
        const control = this.familyForm.get(field);
        if (control) {
          control.markAsTouched({ onlySelf: true });
        }
      });
    }
  }

  toggleInassData(): void {
    if (this.inassForm.valid) {
      let data = {
        params: {
          completed_by: 'staff',
          staff_reason: this.inassForm.value.captureReason,
          collection_place_id: this.inassForm.value.campusSelection,
          staff_member: {
            name: this.inassForm.value.name,
            vat: this.inassForm.value.vat,
            phone: '+58' + this.cleanPhoneNumber(this.inassForm.value.phone),
            // user_nationality: this.inassForm.value.user_nationality,
          },
        },
      };
      this.authService.updateInassSurveryService(data, this.inass_survey_id).subscribe(
        (data) => {
          if (data.result.error) {
            // Armamos la data de la alerta
            const messagesData: CmmAlertToastrModel = {
              typeIcon: 'error',
              text: data.result.error,
            };

            // Abrimos la alerta con el mensaje
            this.cmmDialogsService.CmmAlertToastr(messagesData);
          } else {
            this.authService.updateInassSurveryService(data, this.inass_survey_id).subscribe(
              (data) => {
                this.worker_data = false;
                this.id_hash_pdf = data.result.idhash;
                this.registerCompleted = true;
                this.progress = 100;
                this.tooltipText = '100%';
              },
              (error) => {
                console.error('Error al llamar a la API:', error);
                // Manejar errores aquí
              }
            );
          }
        },
        (error) => {
          console.error('Error al llamar a la API:', error);
          // Manejar errores aquí
        }
      );
    } else {
      Object.keys(this.inassForm.controls).forEach((field) => {
        const control = this.inassForm.get(field);
        if (control) {
          control.markAsTouched({ onlySelf: true });
        }
      });
    }
  }

  reloadView() {
    location.reload();
  }

  getPdf() {
    this.authService.SurveyPdfService(this.id_hash_pdf).subscribe(
      (pdfBlob) => {
        const blob = new Blob([pdfBlob], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);

        // Abrir el PDF en una nueva pestaña
        window.open(url);

        this.registerCompleted = true;
      },
      (error) => {
        console.error('Error al llamar a la API:', error);
        // Manejar errores aquí
      }
    );
  }

  registerResponses(data: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.authService.updateInassSurveryService(data, this.inass_survey_id).subscribe({
        next: (response) => {
          if (response.result.error) {
            // Armamos la data de la alerta
            const messagesData: CmmAlertToastrModel = {
              typeIcon: 'error',
              text: response.result.error,
            };
            // Abrimos la alerta con el mensaje
            this.cmmDialogsService.CmmAlertToastr(messagesData);

            resolve(false);
          } else {
            resolve(true);
          }
        },
        error: (err) => {
          // En caso de error en la llamada al servicio
          // Puedes agregar un manejo de errores aquí si lo necesitas.
          reject(err);
        },
      });
    });
  }

  onSubmit() {
    this.componentForm.markAllAsTouched();
    return;
  }

  ngOnDestroy() {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }
}
